<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
                <p class="mb-6">Excitement is in the air as we invite you to join us for our upcoming events at Starlight Ballroom. From captivating themed parties to elegant galas, our calendar is filled with unforgettable experiences that will leave you in awe. Immerse yourself in a world of enchantment as our meticulously curated events transport you to new realms of entertainment and sophistication. Whether you're a connoisseur of fine cuisine, a lover of live music, or a seeker of unique cultural experiences, our upcoming events offer something for everyone. Stay tuned to our event page for updates and mark your calendars for an extraordinary journey that will create cherished memories and leave you eagerly anticipating the next remarkable occasion.</p>
				<section aria-labelledby="product-heading" class="mt-6 lg:mt-0 lg:col-span-2 xl:col-span-3">
                    <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3">
                        <div class="border border-gray-200 rounded-lg cursor-pointer flex" v-for="event in content.events" :key="event.id">
                            <div class="p-3 border-r border-gray-200 font-bold text-xl">
                                <p class="mb-0 text-center">{{ moment(event?.days?.[0]?.startDate).format('MMM').toUpperCase() }}</p>
                                <p class="mb-0 text-center">{{ moment(event?.days?.[0]?.startDate).format('D') }}</p>
                            </div>
                            <div class="p-3">
                                <p class="mb-0 font-bold text-xl">{{ event.name }}</p>
                                <p class="mb-0">{{event?.metadata?.type}}</p>
                            </div>
                        </div>
                    </div>
                </section>
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import { get } from "lodash";
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            get,
            footerProps,
            moment
        }
    },
    methods: {
        getPricePerEvent(event) {
            const prices = [];
            event.tickets?.map((t) => prices.push(t.pricing[0]?.retailPrice));
            return Math.min.apply(null, prices);
        },
    },
}
</script>

<style>

</style>