<template>
  <UpcomingTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
  />
</template>

<script>
import UpcomingTemplate from "@/components/templates/Upcoming/Upcoming";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    UpcomingTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: "https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari14.png",
        },
      ],
      content: {
        title: "Upcoming events",
        events: []
      },
    };
  },
  async created() {
    const events = await this.actions.event.getEvents({ getValues: true, where: {isActive: true} });
    this.content.events = events;
    console.log("####################", this.state.event.events, "events");
  },
  methods: {},
};
</script>

<style scoped>
</style>
